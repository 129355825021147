.title {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    opacity: 1;
    visibility: visible;
  }
  
  .title.hidden {
    opacity: 0;
    visibility: hidden;
  }
  
  .title.visible {
    opacity: 1;
    visibility: visible;
  }

  /* Example CSS for dynamic text size */
@media (max-width: 576px) {
  .dynamic-text {
    font-size: 12px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .dynamic-text {
    font-size: 14px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .dynamic-text {
    font-size: 16px;
  }
}

@media (min-width: 993px) {
  .dynamic-text {
    font-size: 18px;
  }
}
